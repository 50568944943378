/* eslint-disable */

import { cleanVTags } from "@/components/autocomplete-textarea/slate-helpers";

export function findEncapsulatedStrings(text: string): string[] {
  const regex = /\[\[([\s\S]+?)\]\]/g;
  const matches = text.match(regex);

  if (matches) {
    // Extract the inner content from the matches
    return matches.map((match) => match.slice(2, -2));
  }

  return [];
}

export function findTemplateVariables(text: string): string[] {
  const regex = /\{\{([\s\S]+?)\}\}/g;
  const matches = text.match(regex);

  if (matches) {
    // Extract the inner content from the matches
    return matches.map((match) => match.slice(2, -2));
  }

  return [];
}

function formatPrompt(prompt: string, variables: Map<string, string>) {
  let formattedPrompt = prompt;
  for (const [key, value] of Object.entries(variables)) {
    formattedPrompt = formattedPrompt.replace(
      new RegExp(`{${key}}`, "g"),
      value
    );
  }
  return formattedPrompt;
}

function removeEmojis(s: string): string {
  // Define the emoji pattern using a regular expression
  const emojiPattern =
    /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F1E0}-\u{1F1FF}]/gu;
  return s.replace(emojiPattern, "");
}

export function cleanupSubject(msg: string) {
  console.log("cleanupSubject", msg);
  let splitMsg = msg.split("\n");

  splitMsg = msg.split("\n");

  if (splitMsg[splitMsg.length - 1].length < 1) {
    msg = splitMsg.slice(0, -1).join("\n");
  }

  // Split the message into lines
  splitMsg = msg.split("\n");

  // // Find the index of the first line that contains "your name"
  const index = splitMsg.findIndex((line) =>
    line.toLowerCase().includes("[your name]")
  );

  // console.log("index", index);
  // If such a line is found, return the joined string up to that line
  if (index !== -1) {
    msg = splitMsg.slice(0, index).join("\n");
  }

  splitMsg = msg.split("\n");
  if (splitMsg[0].length < 1) {
    msg = splitMsg.slice(1).join("\n");
  }

  // console.log("cleaned msg", msg);

  return removeEmojis(
    msg
      .split("\n")
      .map((line) => line.trimStart())
      .join("\n")
  );
}

export function cleanupMsg(msg: string) {
  return cleanVTags(
    removeEmojis(
      msg
        .split("\n")
        .map((line) => line.trimStart())
        .join("\n")
    )
  );
}

export function cleanWebsite(website: string) {
  const cleanedWebsite = website
    .replace(/^http:\/\/|^https:\/\/|^www\./, "")
    .replace("www.", "")
    .replace(/\/$/, "");
  return cleanedWebsite.split("/")[0].toLowerCase().trim();
}

export function cleanWebsiteForComparison(website: string) {
  return website
    .replace(/^http:\/\/|^https:\/\/|^www\./, "")
    .replace(/\/$/, "")
    .toLowerCase()
    .trim();
}
