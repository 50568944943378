"use client";

import Link from "next/link";
import { AwesomeButton } from "react-awesome-button";
import "./try-lavareach-button.css";

export const TryLavaReachButton = () => {
  return (
    <Link href="/dashboard" className="z-20">
      <AwesomeButton type="primary">Research Your List Now</AwesomeButton>
    </Link>
  );
};

export const EduCTAButton = () => {
  return (
    <Link href="/schedule" className="z-20">
      <AwesomeButton type="primary">
        Reserach Your List of Schools
      </AwesomeButton>
    </Link>
  );
};
