"use client";
import Balancer from "react-wrap-balancer";
import { useState } from "react";
import { StepButton } from "../ui/landing-step-card";
import { cn } from "@/lib/utils";

const Buttons = [
  {
    title: "Chatbot company research with multiple saved chat histories",
    description:
      "Chat with each company to find deeper research, access old chats in one place.",
    video: "/landing/chat_muted.mp4",
  },
  {
    title: "Build your own account plan templates",
    description:
      "Save research prompts that show for each company in your list to standardize research and personalization.",
    video: "/landing/account_card_muted.mp4",
  },
  {
    title: "View citations and sources",
    description: "See where we found the data for each research point.",
    video: "/landing/citations.mp4",
  },
];

export default function Step3() {
  const [step, setStep] = useState(1);

  return (
    <div className="h-full w-full m-auto">
      <div className="w-full h-full pt-lg">
        <div className="text-center h-full flex flex-col items-center gap-lg">
          <h2 className="max-w-[1100px] font-bold bg-clip-text text-text-landing">
            <span className="text-lavareachOG text-lg"> Step 3:</span>
            <br />
            <span className="text-3xl">
              <Balancer>
                Dig deeper into each company with account cards
              </Balancer>
            </span>
          </h2>

          <div className="grid grid-cols-2 w-full">
            <div className="h-full p-4 flex flex-col gap-sm">
              {/* <div
                className={cn(
                  "h-full group relative flex flex-col justify-between overflow-hidden rounded-xl",
                  "rounded-r-none",
                  // light styles
                  "bg-white [box-shadow:0_0_0_1px_rgba(0,0,0,.03),0_2px_4px_rgba(0,0,0,.05),0_12px_24px_rgba(0,0,0,.05)]",
                  // dark styles
                  "transform-gpu dark:bg-black dark:[border:1px_solid_rgba(255,255,255,.1)] dark:[box-shadow:0_-20px_80px_-20px_#ffffff1f_inset]",

                  "h-full p-4 pr-0 flex flex-col"
                )}
              > */}
              {Buttons.map((button, index) => (
                <button
                  key={index}
                  onClick={() => {
                    if (button.video) setStep(index);
                  }}
                  className="w-full"
                >
                  <StepButton
                    selected={step === index}
                    name={button.title}
                    description={button.description}
                  />
                </button>
              ))}
              {/* </div> */}
            </div>
            <div className="h-full p-4">
              <div className={cn(step === 0 ? "block" : "hidden")}>
                <video width="960" height="480" controls muted autoPlay loop>
                  <source src={"/landing/chat_muted.mp4"} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>

              <div className={cn(step === 1 ? "block" : "hidden")}>
                <video width="960" height="480" controls muted autoPlay loop>
                  <source
                    src={"/landing/account_card_muted.mp4"}
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </div>

              <div className={cn(step === 2 ? "block" : "hidden")}>
                <video width="960" height="480" controls muted autoPlay loop>
                  <source src={"/landing/citations.mp4"} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
