import { StripeSubscriptionType } from "@/utils/db/types";

export const subscriptionPrices = {
  [StripeSubscriptionType.NoSubscription]: 0,
  [StripeSubscriptionType.Credits19dot99]: 20,
  [StripeSubscriptionType.Growth99]: 99,
};

export const subscriptionCredits = {
  [StripeSubscriptionType.NoSubscription]: 0,
  [StripeSubscriptionType.Credits19dot99]: 500,
  [StripeSubscriptionType.Growth99]: 2500,
};

export const subscriptionFeatures = {
  [StripeSubscriptionType.NoSubscription]: [
    "20 AI Enrichment Credits",
    "Email support",
    "Chrome extension tool",
  ],

  [StripeSubscriptionType.Credits19dot99]: [
    "500 Credits",
    "AI Company Research",
    "Account Cards",
    "Chrome Extension Tool",
    "More credits as you go",
  ],

  [StripeSubscriptionType.Growth99]: [
    "Everything in Starter",
    "2500 Credits",
    "Contact Finder",
    "Email / Phone enrichment",
    "Slack Support",
  ],
};

export const enterpriseFeatures = [
  "Everything in Premium",
  "Unlimited Credits",
  "Custom List Building",
  "Custom Research",
  "CRM Integration",
];
