/* eslint-disable */
import { type ReactNode } from "react";
import { ArrowRightIcon } from "@radix-ui/react-icons";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import Balancer from "react-wrap-balancer";

const LandingStepCard = ({
  name,
  className,
  background,
  left,
  description,
  href,
  cta,
}: {
  name: string;
  className: string;
  background: ReactNode;
  left: boolean;
  description: string;
  href: string;
  cta: string;
}) => (
  <div
    key={name}
    className={cn(
      "h-full group relative col-span-3 flex flex-col justify-between overflow-hidden rounded-xl",
      left ? "rounded-r-none" : "rounded-l-none",
      // light styles
      "bg-white [box-shadow:0_0_0_1px_rgba(0,0,0,.03),0_2px_4px_rgba(0,0,0,.05),0_12px_24px_rgba(0,0,0,.05)]",
      // dark styles
      "transform-gpu dark:bg-black dark:[border:1px_solid_rgba(255,255,255,.1)] dark:[box-shadow:0_-20px_80px_-20px_#ffffff1f_inset]",
      className
    )}
  >
    <div className="h-full">{background}</div>
    <div className="pointer-events-none z-[20] flex transform-gpu flex-col gap-1 transition-all duration-300 group-hover:-translate-y-10">
      <h3
        className={cn(
          "bg-white w-full p-6 pb-0 transform-gpu dark:bg-black",
          "text-xl font-semibold text-text-landing dark:text-neutral-300"
        )}
      >
        {name}
      </h3>
      <p
        className={cn(
          "max-w-lg text-text-secondary p-6 bg-white w-full pt-0 transform-gpu dark:bg-black"
        )}
      >
        {description}
      </p>
    </div>

    {
      <div
        className={cn(
          "pointer-events-none absolute bottom-0 flex w-full translate-y-10 transform-gpu flex-row items-center p-4 opacity-0 transition-all duration-300 group-hover:translate-y-0 group-hover:opacity-100"
        )}
      >
        <Button
          variant="ghost"
          asChild
          size="sm"
          className="pointer-events-auto"
        >
          <a href={href}>
            {cta}
            <ArrowRightIcon className="ml-2 h-4 w-4" />
          </a>
        </Button>
      </div>
    }
    {/* <div className="pointer-events-none absolute inset-0 transform-gpu transition-all duration-300" /> */}
  </div>
);

const StepButton = ({
  name,
  className,
  description,
  selected,
}: {
  name: string;
  className?: string;
  description: string;
  selected?: boolean;
}) => (
  <div
    key={name}
    className={cn(
      "h-full group relative col-span-3 flex flex-col justify-between overflow-hidden rounded-md",
      selected ? "border-lavareachOG border-2" : "border-transparent",
      // light styles
      "bg-white [box-shadow:0_0_0_1px_rgba(0,0,0,.03),0_2px_4px_rgba(0,0,0,.05),0_12px_24px_rgba(0,0,0,.05)]",
      // dark styles
      "transform-gpu dark:bg-black dark:[border:1px_solid_rgba(255,255,255,.1)] dark:[box-shadow:0_-20px_80px_-20px_#ffffff1f_inset]",
      className
    )}
  >
    <div className="pointer-events-none z-[20] flex transform-gpu flex-col gap-1 transition-all duration-300">
      <h3
        className={cn(
          "bg-white text-start w-full p-6 pb-0 transform-gpu dark:bg-black",
          "text-xl font-semibold text-text-landing dark:text-neutral-300"
        )}
      >
        <Balancer>{name}</Balancer>
      </h3>
      <p
        className={cn(
          "max-w-lg text-start text-text-secondary p-6 bg-white w-full pt-0 transform-gpu dark:bg-black"
        )}
      >
        {description}
      </p>
    </div>
  </div>
);

export { LandingStepCard, StepButton };
